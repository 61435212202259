import React from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Grid, type BoxProps } from '@mantine/core'

interface GridContainerProps extends BoxProps {
  /** <Col /> components only */
  children: React.ReactNode
  /** Set grid justify-content property */
  justify?: React.CSSProperties['justifyContent']
  /** Set grid align-content property */
  align?: React.CSSProperties['alignContent']
  /** Should columns in the last row take 100% of grid width */
  grow?: boolean
}

type GridContainerComponent = ((props: GridContainerProps) => React.ReactElement) & {
  Col: typeof Grid.Col
}

export const GridContainer: GridContainerComponent = props => (
  <Grid
    css={{ maxWidth: 1416 }}
    grow={props.grow}
    columns={12}
    gutter={{ base: 16, xl: 24 }}
    px={{ base: 16, lg: 24, xl: 64 }}
    mb={{ base: 12, lg: 20 }}
    mx={{ lg: 'auto' }}
    styles={{
      inner: {
        rowGap: 12,
        height: '100%',
      },
    }}
    {...props}
  >
    {props.children}
  </Grid>
)

GridContainer.Col = Grid.Col
