// Allowed use by IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { MantineProvider, type MantineThemeOverride, type MantineColorsTuple } from '@mantine/core'
import React, { type FunctionComponent } from 'react'
import {
  ThemeProvider as IDSThemeProvider,
  INSTACART_SANS_FONT_FAMILY,
  FontLoader,
  InstacartSansFontLoaderVariants,
} from '@instacart/ids-core'
import { GlobalStyles } from '../../utils/styling/GlobalStyles'
import { colors } from '../../foundation'
import { breakpoints } from '../../hooks/useGetCurrentBreakpoint.hooks'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/carousel/styles.css'

export type ThemeProviderProps = {}

const themeOverrides: MantineThemeOverride = {
  fontFamily: `'${INSTACART_SANS_FONT_FAMILY}'`,
  colors: {
    brand: [
      colors.PRIMARY.REGULAR,
      colors.PRIMARY.DARK,
      colors.PRIMARY.EXTRA_DARK,
    ] as unknown as MantineColorsTuple,
  },
  ...breakpoints,
  respectReducedMotion: false,
  primaryColor: 'brand',
  primaryShade: 0,
  components: {
    // See (v7) Mantine docs https://mantine.dev/styles/styles-api/#classnames-in-themecomponents
    // We apply classNames defined in GlobalStyles to components to override Mantine's default styles
    DatePicker: {
      classNames: {
        calendarHeader: 'internal-mantine-Calendar-calendarHeader',
        calendarHeaderLevel: 'internal-mantine-Calendar-calendarHeaderLevel',
        monthCell: 'internal-mantine-Calendar-monthCell',
        month: 'internal-mantine-Calendar-month',
        yearsListControl: 'internal-mantine-Calendar-yearPickerControl',
        monthsListControl: 'internal-mantine-Calendar-monthPickerControl',
        day: 'internal-mantine-Calendar-day',
        weekday: 'internal-mantine-Calendar-weekday',
      },
      defaultProps: {
        weekendDays: [],
      },
    },
    DateInput: {
      classNames: {
        calendarHeader: 'internal-mantine-Calendar-calendarHeader',
        calendarHeaderLevel: 'internal-mantine-Calendar-calendarHeaderLevel',
        monthCell: 'internal-mantine-Calendar-monthCell',
        month: 'internal-mantine-Calendar-month',
        yearsListControl: 'internal-mantine-Calendar-yearPickerControl',
        monthsListControl: 'internal-mantine-Calendar-monthPickerControl',
        day: 'internal-mantine-Calendar-day',
        weekday: 'internal-mantine-Calendar-weekday',
      },
      defaultProps: {
        weekendDays: [],
      },
    },

    Popover: {
      defaultProps: {
        shadow: 'md',
        withinPortal: true,
      },
      classNames: {
        dropdown: 'mantine-Popover-dropdown',
      },
    },
  },
}

export const ThemeProvider: FunctionComponent<React.PropsWithChildren<ThemeProviderProps>> = ({
  children,
}) => (
  <MantineProvider theme={themeOverrides}>
    <FontLoader
      fontFamily={INSTACART_SANS_FONT_FAMILY}
      variants={InstacartSansFontLoaderVariants}
    />
    <IDSThemeProvider>
      <GlobalStyles />
      {children}
    </IDSThemeProvider>
  </MantineProvider>
)
