import React, {
  type FunctionComponent,
  type BaseSyntheticEvent,
  useCallback,
  useEffect,
} from 'react'
import { InputBase } from '@instacart/ids-tooling'
import { useForm } from 'react-hook-form-7'
import { useFormErrorMessages } from '../../../../hooks/intl/useFormErrorMessages.hooks'
import { Alert } from '../../../alert/Alert'
import { Button } from '../../../button/Button'
import { FormField, FormFieldError, FormFieldLabel } from '../../../form-field/FormField'
import { PublicLayout } from '../../../../layouts/public-layout/PublicLayout'
import { emailValidationPattern } from '../../utils/utils'
import { useExtraFields } from '../../utils/useExtraFields.hooks'
import { type MessageIds, useMessages } from '../../../../utils/intl/intl.hooks'
import { spacing } from '../../../../foundation'
import { PublicCard } from '../../utils/styles'

type FormData = {
  email: string
}

export type LoginPreflightProps = {
  disabled?: boolean
  onSubmit?: (data: FormData, evt: BaseSyntheticEvent | undefined) => void
  errorMessage?: string
  initialFormState?: FormData
  action?: string
  method?: string
  extraFields?: Record<string, string>
  testidPrefix?: string
}

export const LoginPreflight: FunctionComponent<React.PropsWithChildren<LoginPreflightProps>> = ({
  disabled,
  children,
  onSubmit,
  errorMessage,
  initialFormState,
  action,
  method,
  extraFields,
  testidPrefix = 'LoginPreflight',
}) => {
  const { register, handleSubmit, formState, setFocus } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: initialFormState,
  })
  const { errors, isValid } = formState
  const renderedExtraFields = useExtraFields(extraFields, register)

  const errorMessages = useFormErrorMessages<FormData, MessageIds>(errors, {
    email: {
      default: 'sharedComponents.publicPages.logInForm.errors.email.default',
      pattern: 'sharedComponents.publicPages.logInForm.errors.email.pattern',
    },
  })

  const messages = useMessages({
    headline: 'sharedComponents.publicPages.logInForm.preflight.headline',
    description: 'sharedComponents.publicPages.logInForm.preflight.description',
    emailLabel: 'sharedComponents.publicPages.loginForm.email.label',
    emailPlaceholder: 'sharedComponents.publicPages.loginForm.email.placeholder',
    continue: 'sharedComponents.publicPages.loginForm.continue',
  })

  const onSubmitCallback = useCallback(
    (evt: BaseSyntheticEvent) => {
      if (onSubmit) return handleSubmit(onSubmit)(evt)
      if (!isValid) evt.preventDefault()
    },
    [onSubmit, handleSubmit, isValid]
  )

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  return (
    <PublicLayout>
      <PublicCard>
        <div>
          <PublicCard.Title>{messages.headline}</PublicCard.Title>
          <PublicCard.Subtitle>{messages.description}</PublicCard.Subtitle>
        </div>

        {children}

        <form
          data-testid={`${testidPrefix}-form`}
          action={action}
          method={method}
          onSubmit={onSubmitCallback}
          css={{ display: 'contents' }}
        >
          <FormField noMargin>
            <FormFieldLabel htmlFor="email">{messages.emailLabel}</FormFieldLabel>
            <InputBase
              id="email"
              {...register('email', { required: true, pattern: emailValidationPattern })}
              placeholder={messages.emailPlaceholder}
              data-testid={`${testidPrefix}-field-email`}
              autoComplete="email"
              disabled={disabled}
            />

            {errorMessages.email && (
              <FormFieldError data-testid={`${testidPrefix}-field-error-email`}>
                {errorMessages.email}
              </FormFieldError>
            )}
          </FormField>

          {errorMessage && (
            <Alert
              data-testid={`${testidPrefix}-form-error`}
              variant="danger"
              css={{ marginBottom: spacing.X24 }}
            >
              {errorMessage}
            </Alert>
          )}

          {renderedExtraFields}

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: spacing.X24,
            }}
          >
            <Button
              type="submit"
              data-testid={`${testidPrefix}-form-submit`}
              css={{ margin: 0 }}
              disabled={disabled || !isValid}
            >
              {messages.continue}
            </Button>
          </div>
        </form>
      </PublicCard>
    </PublicLayout>
  )
}
