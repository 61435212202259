import React, { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import { Portal as BasePortal } from '../portal/Portal'

type TPortalTarget = React.FunctionComponent<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
>
type TPortal = React.FunctionComponent<React.PropsWithChildren<unknown>>
type TargetedPortalTuple = [
  /**
   * The TargetPortal component, where the elements will show up
   */
  portalTarget: TPortalTarget,
  /**
   * The Portal component, which sends the elements into the Target
   */
  portal: TPortal
]

/**
 * DO NOT USE THIS INSIDE OF A REACT COMPONENT
 *
 * Allows sending elements from wherever you want to a specific target.
 * This factory returns a tuple consisting on:
 * 1. The TargetPortal component, where the elements will show up
 * 2. The Portal component, which sends the elements into the Target
 *
 * Note that the TargetPortal should exist for the lifetime of the Portal component.
 */
export const getTargetedPortal = ({ id }: { id?: string } = {}): TargetedPortalTuple => {
  const PORTAL_TARGET_ID = id || `portal-target-${nanoid()}`

  const PortalTarget: TPortalTarget = props => (
    <div {...props} data-testid={PORTAL_TARGET_ID} id={PORTAL_TARGET_ID} />
  )

  const Portal: TPortal = ({ children }) => {
    const [element, setElement] = useState<HTMLElement>()

    /**
     * This is an useEffect instead of a useMemo because attempting to mount both at the same time
     * could result in the portal target element to not have been created yet.
     *
     * Some alternatives if this causes issues: useLayoutEffect, polling for the element.
     */
    useEffect(() => {
      const found = document.getElementById(PORTAL_TARGET_ID)
      if (!found) {
        console.error(
          `TargetedPortal: target ${PORTAL_TARGET_ID} was not found. The target must be mounted before trying to portal any content. Current passed content will be ignored.`
        )
        return
      }

      setElement(found)
    }, [])

    if (!element) return null

    return <BasePortal portalContainer={element}>{children}</BasePortal>
  }

  return [PortalTarget, Portal]
}
