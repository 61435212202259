import React, { useState, type FunctionComponent, useEffect } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@instacart/ids-core'
// Approved by the IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { DatePicker, type DatePickerProps, type DateValue } from '@mantine/dates'
import { type DateRange } from '../DateRangePickerV2'
import { colors } from '../../../foundation'

interface DateRangePickerCalendarProps {
  dates: DateRange
  onChange: (dates: [DateValue, DateValue]) => void
  excludeDate?: DatePickerProps['excludeDate']
}

export const DateRangePickerCalendar: FunctionComponent<
  React.PropsWithChildren<DateRangePickerCalendarProps>
> = ({ dates, onChange, excludeDate }) => {
  const [visibleMonth, setVisibleMonth] = useState(new Date())
  useEffect(() => {
    if (dates.start) {
      setVisibleMonth(new Date(dates.start))
    }
  }, [dates.start])

  return (
    <DatePicker
      data-testid="date-range-picker-calendar"
      type="range"
      firstDayOfWeek={0}
      maxLevel="month"
      date={visibleMonth}
      previousIcon={<ArrowLeftIcon size={24} />}
      nextIcon={<ArrowRightIcon size={24} />}
      onDateChange={date => {
        setVisibleMonth(selectedMonth => {
          const newMonth = new Date(selectedMonth)
          newMonth.setMonth(date.getMonth())
          newMonth.setFullYear(date.getFullYear())
          return newMonth
        })
      }}
      hideOutsideDates
      value={[dates.start, dates.end]}
      onChange={onChange}
      allowSingleDateInRange
      styles={{
        levelsGroup: { maxWidth: '560px' },
        calendarHeaderLevel: { color: colors.GRAYSCALE.X70 },
      }}
      excludeDate={excludeDate}
      // no weekend days because we don't want them styled differently from weekdays
      weekendDays={[]}
    />
  )
}
