import React, { type FunctionComponent } from 'react'
// Approved by the IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { DateInput, type DateValue } from '@mantine/dates'
import { DateIcon } from '@instacart/ids-core'
import { colors, borderRadius, fontWeight, spacing } from '../../../foundation'
import { DateRangePickerLabelText } from './DateRangePickerLabelText'

interface DateRangePickerDateInputProps {
  date: DateValue
  onChange: (date: DateValue) => void
  label: string
}

export const DateRangePickerDateInput: FunctionComponent<
  React.PropsWithChildren<DateRangePickerDateInputProps>
> = ({ date, onChange, label }) => (
  <div css={{ width: 260 }}>
    <DateRangePickerLabelText>{label}</DateRangePickerLabelText>
    <DateInput
      value={date}
      onChange={onChange}
      popoverProps={{
        styles: {
          dropdown: {
            display: 'none',
          },
        },
      }}
      styles={{
        input: {
          border: `1px solid ${colors.GRAYSCALE.X30}`,
          borderRadius: borderRadius.X8,
          height: '40px',
          color: colors.GRAYSCALE.X80,
          fontSize: '15px',
          fontWeight: fontWeight.SEMIBOLD,
          paddingRight: spacing.X24,
        },
      }}
      leftSection={<DateIcon size={18} />}
    />
  </div>
)
